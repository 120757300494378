<template>
  <div>
    <p>
      <u>Ninjago Scrolls</u>
      <br>The Ninjago Scrolls in Crux Prime will now reappear faster after they have been snatched up by another Minifigure. This means the Scrolls should be a bit easier to find, because you can no longer accidentally pass one, which has just been picked up by someone else, and hasn’t yet respawned.
    </p>
    <p>
      <u>Nuckal and Roo Morgg</u>
      <br>The loot drop-rates for these two beasts have been adjusted. Now, you will have to make a greater effort to get the really cool items. Prepare for battle!
    </p>
    <p>
      <u>Dragon Helm KMIII</u>
      <br>No more bald heads! The Dragon Helm (+5 Armor, +5 Imagination, it’s a good helm!) now shows up as it should.
    </p>
    <p>
      <u>Leaderboards</u>
      <br>All in-game leaderboards will be wiped with this release to give us all a fresh start!
    </p>
    <p>
      <u>Building</u>
      <br>We are currently seeing a building issue, where Property Models sometimes disappear. A temporary workaround, until we get this completely fixed, is to make sure you leave Building Mode if you intend on leaving your computer for a longer period of time. If you are idle for more than 15 minutes, you will be sent back to the login page, and your Models might not have been saved. So make sure to leave Building Mode if you are leaving your computer!
    </p>
  </div>
</template>
